import React, { useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';



export const SearchHeader = ({ filters, setFilters, setGlobalFilterValue, globalFilterValue }) => {

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const clearFilter = () => {
        let _filters = { ...filters };

        _filters['global'].value = "";
        setFilters(_filters);
        setGlobalFilterValue("");


    };

    return (
        <div className="flex justify-content-end">
            <span className="p-input-icon-left p-input-clear-icon-right">
                <i className="pi pi-search" />
                <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Keyword Search"
                />
                {globalFilterValue && (
                    <Button
                        type="button"
                        icon="pi pi-times"
                        className="p-button-text p-clear-button"
                        onClick={clearFilter}
                    />
                )}
            </span>
        </div>
    );
};