import React from 'react';
import { Dropdown } from 'primereact/dropdown';

/*
    filter - FilterElement,
    options - [{label, value}, ...],
    name - ""
*/
export const DropdownFilterTemplate = (filter, options, name, filterOptions) => {
	let opts = (filterOptions == null || filterOptions == undefined)  ? options.filter(val => val !== null) : filterOptions;

	return (
		<Dropdown value={filter.value}
			options={opts}
			onChange={(e) => filter.filterApplyCallback(e.value)}
			placeholder={name}
			className="p-column-filter"
		/>
	);
}