import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../UserContext";
import { AcaaMap } from '../components/AcaaMap.js';
import { LocationSensorTable } from '../components/LocationSensorTable';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Card } from "primereact/card";
import PreferencesBar from '../components/PreferenceDropdown.js';
import { Accordion, AccordionTab } from 'primereact/accordion';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const zoom = 15.4;
const fiveMin = 5 * 60;

export const LocationSensorMap = (props) => {
	const [sensorMetaData, setSensorMetaData] = useState([]);
	const [currentMarkers, setCurrentMarkers] = useState([]);
	// const [selectedPreferences, setSelectedPreferences] = useState();
	const [activeIndex, setActiveIndex] = useState(-1);
	const [filterDepts, setFilterDepts] = useState();

	const user = useContext(UserContext);
	const map = useRef(null);
	const preferences = [];

	const [userPresetFilters, setUserPresetFilters] = useState([]);
	const [deptMetaData, setDeptMetaData] = useState([]);
	const [allDepts, setAllDepts] = useState([]);

	const filtersRef = useRef(userPresetFilters);


	// ********START OF API CALL DEFINITIONS********
	const fetchAssetData = async (chosenPreferences) => {

		//I refactored this fetch data, it used to do two different fetches, one hit LocationNow and the other hit LocationSensors
		//They were returning seemingly the same data, so I created a viewmodel to return everything ONCE
		try {
			console.log(chosenPreferences);
			await user.apiCall(`LocationNow`, { preferences: chosenPreferences || userPresetFilters, searchTerm: props.globalFilterValue }, "POST")
				.then((res) => {
					const now = new Date();
					console.log("fetchAssetData: " + now.toString())
					setSensorMetaData(res.data);
					getSetUniqueDepartmentValues(res.data);
				});
		}
		catch (error) {
			console.error(error.message)
		}
	}

	useEffect(() => {
		filtersRef.filters = userPresetFilters;
	}, [userPresetFilters]);

	useEffect(() => {
		let departments = [];
		let activeOptions = [];
		let userPref = [];

		let isMounted = true; // Flag to check if component is still mounted
		const fetchInitialData = async () => {
			console.log("in initial fetch");

			try {
				userPref !== null ? await fetchAssetData(userPref) : await fetchAssetData();

			} catch (error) {
				console.error('Error fetching data:', error);
			}
			// repeat.current = setInterval(() => fetchAssetData(userPresetFilters ? userPresetFilters : departments), 5 * 40 * 1000);

		};

		const fetchMetaData = async () => {
			const res = await user.apiCall("findOptions");
			const optionArray = res.data.map(option => ({
				name: option.assetDepartmentName,
				value: option.assetDepartmentName,
				color: option.assetDepartmentColor,
				default: option.assetDepartmentDefault
			}));

			optionArray.forEach(option => {
				if (option.default) {
					activeOptions.push(option);
					departments.push(option.name);
				}
			});

			getUserPreferences();
			setDeptMetaData(activeOptions);
			setAllDepts(departments);

		}

		const getUserPreferences = async () => {
			console.log(user.userInfo);
			const preferencesRes = await user.apiCall(`selectedPreferences/${user.userInfo.email}`);
			console.log(preferencesRes);
			if (preferencesRes?.data?.preferences !== null && preferencesRes?.data?.preferences !== undefined) {
				userPref = JSON.parse(preferencesRes?.data?.preferences);
				userPref = userPref.filter(pref => departments.includes(pref));
				setUserPresetFilters(userPref);
			}

			fetchInitialData();

		}
		if(user.userInfo !== null && user.userInfo !== undefined){
			console.log(user.userInfo);
			fetchMetaData();

		}



		// Clean up function
		// return () => {
		// 	isMounted = false; // Component is unmounting
		// 	if (repeat.current) {
		// 		clearInterval(repeat.current);
		// 	}
		// };
	}, []);

	//this is used to set the markers on the map, need to do this everytime the sensor data updates (every 60 seconds)
	useEffect(() => {
		if (sensorMetaData) {
			const serialList = sensorMetaData.map((ftr) => ftr.properties.assetSerial)
			assembleMarkers(serialList);
		}
		// if (repeat.current) {
		// 	clearInterval(repeat.current)
		// 	repeat.current = setInterval(() =>
		// 		fetchAssetData(userPresetFilters), 40 * 1000);
		// }
	}, [sensorMetaData]);

	useEffect(() => {
		let intervalId = setInterval(() => { fetchAssetData(filtersRef.filters) }, 60 * 1000);
		return () => { console.log("in clear:"); clearInterval(intervalId) }
	}, [])


	const assembleMarkers = (assetSerialList) => {
		//need the meta data to assemble the markers, because it has the colors
		try {
			for (let i = currentMarkers.length - 1; i >= 0; i--) {
				currentMarkers[i].remove();
				currentMarkers.pop();
			}
			if (sensorMetaData?.length > 0 && map.current) {


				for (const feature of sensorMetaData) {
					if (!assetSerialList.find((val) => val === feature.properties.assetSerial)) {
						continue;
					}
					// create a HTML element for each feature
					const el = document.createElement('div');
					const elRect = document.createElement('div');
					const elTri = document.createElement('div');
					el.id = 'marker' + feature.properties.assetSerial;
					let rectClass = "acaa-marker"

					const timeStr = diffTimeAgo(feature.properties.eventDateTime);
					let hourCheck = timeStr.match(/\d+(?= hrs)/);
					hourCheck = hourCheck ? parseInt(hourCheck[0]) : 0;
					if (hourCheck >= 6) {
						rectClass += " out-of-range";
					}
					var deptObj = deptMetaData.filter(obj => {
						return obj.value === feature.properties.department
					})

					elRect.className = rectClass;
					elRect.style.background = '#' + deptObj[0].color
					el.appendChild(elRect);

					elTri.className = 'triangle-down ';
					el.appendChild(elTri);

					// make a marker for each feature and add it to the map
					const marker = new mapboxgl.Marker(el)
						.setLngLat(feature.geometry.coordinates)
						.setPopup(
							new mapboxgl.Popup({
								closeButton: false,
								closeOnClick: false,
								offset: 25
							}) // add popups
								.setHTML(assemblePopup(feature.properties))
						)
						.addTo(map.current);

					currentMarkers.push(marker)
					const markerDiv = marker.getElement();
					markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
					markerDiv.addEventListener('mouseleave', () => marker.togglePopup());
					markerDiv.addEventListener('click', (e) => e.stopPropagation());
				}
			}

		}
		catch (exception) {
			console.error(exception)
		}
	}
	const updatePreferences = async (preferences) => {
		console.log(preferences);
		setUserPresetFilters(preferences);
		fetchAssetData(preferences)
		let reqBody = { user: user.userInfo.email, preferences: preferences }
		await user.apiCall("selectedPreferences", reqBody, "POST")
	}
	const assemblePopup = (data) => {
		const timeStr = diffTimeAgo(data.eventDateTime);
		let hourCheck = timeStr.match(/\d+(?= hrs)/);
		hourCheck = hourCheck && parseInt(hourCheck[0]) > 5 ? "old" : "";
		var deptObj = deptMetaData.filter(obj => {
			return obj.value === data.department
		})
		var bgColor = '#' + deptObj[0].color;
		return `
            <div class="${hourCheck}">
                <div class="visual">
                    <div style="background-color: ${bgColor}" class="name ${data.department.toLowerCase()}" >
                        ${data.makeAndModel}
                    </div>
                    <img alt="Asset image" src=${data.imageURL} />
                </div>
                <div class="data">
                    <div class="time">
						<i class="pi pi-eye"></i><span>${diffTimeAgo(data.eventDateTime)} ago</span>
					</div>
                    <div class="asset-label">${data.assetSerial}</div>
                </div>
            </div>`
	}

	const diffTimeAgo = (feature) => {
		let edt = new Date(feature);
		let now = new Date();
		let dMs = now - edt;

		const minsDiff = Math.floor(dMs / (60 * 1000)) % 60;
		const hrsDiff = Math.floor(dMs / (60 * 60 * 1000));

		let toReturn = "";
		if (hrsDiff > 0) {
			toReturn += `${hrsDiff} hr`;
			toReturn += hrsDiff > 1 ? "s " : " ";
		}
		toReturn += `${minsDiff} min`;
		if (minsDiff > 1) {
			toReturn += "s";
		}

		return toReturn;
	}

	const handleTableFilter = (fltrData) => {
		const serialList = fltrData.map((item) => item.properties.assetSerial);
		getSetUniqueDepartmentValues(fltrData);
		assembleMarkers(serialList);
	}

	const getSetUniqueDepartmentValues = (fltrData) => {
		const allDepartments = fltrData.map((item) => item.properties.department);
		const uniqueDepartments = [...new Set(allDepartments)];
		setFilterDepts(uniqueDepartments);
	}

	const centerOn = (coords, id) => {
		map.current.flyTo({ center: coords, zoom: 16.4 });
		var marker = currentMarkers.filter(obj => {
			return obj._element.id === id
		})
		marker[0].togglePopup();
		setTimeout(function () {
			marker[0].togglePopup();
		}, 3000);

	}

	const togglePane = () => {
		if (activeIndex === 0) {
			setActiveIndex(-1);
		} else if (activeIndex === -1) {
			setActiveIndex(0);
		}
	};

	return (
		<span id="LocSensMap">
			<AcaaMap mapRef={map}
				startZoom={zoom}
				showJumpers
			>
				<PreferencesBar selectedPreferences={userPresetFilters}
					updatePreferences={updatePreferences}
					preferenceOptions={deptMetaData} />
			</AcaaMap>
			<a href="https://stacaaintegrations.blob.core.windows.net/user-guides/FindUserGuide.pdf" download="RolodexUserGuide.pdf" target="_blank" rel="noreferrer">
				<i className="map-overlay-help pi pi-question-circle"></i>
			</a>
			<div className="map-overlay">

				<Card className='filter-style'>
					<Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
						<AccordionTab header="Asset Detail">
							<div className="loc-table">
								<LocationSensorTable data={sensorMetaData}
									centerFunc={centerOn}
									handleFilter={handleTableFilter}
									deptList={(userPresetFilters?.length == 0 ? allDepts : userPresetFilters)}
									deptInfo={deptMetaData}
									handleAssetClick={togglePane}
									setGlobalFilterValue={props.setGlobalFilterValue}
									globalFilterValue={props.globalFilterValue}
									departmentFilters={filterDepts}
								/>
							</div>
						</AccordionTab>
					</Accordion>
				</Card>
			</div>
		</span>
	);
};
