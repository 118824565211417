import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DropdownFilterTemplate } from '../utilities/FilterUtil';
import { SearchHeader } from './SearchHeader';

const defaultFilters = {
	"global": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"properties.assetSerial": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
	"properties.makeAndType": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
	"properties.make": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
	"properties.department": { value: null, matchMode: FilterMatchMode.EQUALS }
}

export const LocationSensorTable = (props) => {
	const [filters, setFilters] = useState(defaultFilters);

	useEffect(() => {
		setFilters(defaultFilters);
	}, [props.data])
	const clickBody = (rowData) => {
		var deptObj = props.deptInfo.filter(obj => {
			return obj.value === rowData.properties.department
		})
		return (
			<Button style={{ background: '#' + deptObj[0].color + '90', minWidth: 70, justifyContent: 'space-around', color: 'black', fontWeight: 'bold', fontSize: "1rem" }}
				text
				raised
				onClick={() => onAssetButtonClick(rowData.properties)}>
				{rowData.properties.assetSerial}
			</Button>)
	};

	const onAssetButtonClick = (rowData) => {
		console.log(rowData);
		props.centerFunc([rowData.lon, rowData.lat], 'marker' + rowData.assetSerial);
		props.handleAssetClick();
	}

	return (

		<DataTable value={props.data}
			size="small"
			filters={filters}
			onFilter={(e) => setFilters(e.filters)}
			onValueChange={filtered => props.handleFilter(filtered)}
			showGridlines
			scrollable
			rowHover
			className='locTable'
			header={<SearchHeader filters={filters} setFilters={setFilters} setGlobalFilterValue={props.setGlobalFilterValue} globalFilterValue={props.globalFilterValue} departmentFilters={props.departmentFilters} />}
		>
			<Column field="properties.assetSerial"
				header="ID"
				headerStyle={{ fontWeight: 700 }}
				body={clickBody}
				showFilterMatchModes={false}
				showFilterMenuOptions={false}
				// filter
			/>
			<Column field="properties.makeAndType"
				headerStyle={{ fontWeight: 700 }}
				header="Type/Make"
				showFilterMatchModes={false}
				showFilterMenuOptions={false}
				// filter
			/>
			<Column field="properties.department"
				headerStyle={{ fontWeight: 700 }}
				header="Dept"
				bodyStyle={{ minWidth: '75px', textAlign: 'center' }}
				filterElement={(f) => DropdownFilterTemplate(f, props.deptList, "Dept", props.departmentFilters)}
				showFilterMatchModes={false}
				showFilterMenuOptions={false}
				// filter
			/>
		</DataTable>
	);
}
