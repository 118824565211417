import React from 'react';
import { MultiSelect } from 'primereact/multiselect';

const PreferencesBar = ({ selectedPreferences, updatePreferences, preferenceOptions }) => {
    return (
        <MultiSelect
            value={selectedPreferences}
            className="preference-dropdown"
            onChange={(e) => updatePreferences(e.value)}
            options={preferenceOptions}
            optionLabel="name"
            display="chip"
            placeholder="Select Department..."
            maxSelectedLabels={4}
        />
    );
};

export default PreferencesBar;